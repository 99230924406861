import React from 'react';
import SEO from '../components/SEO';

export default function DonatePage() {
  return (
    <>
      <SEO title="Credits" />
      <h1>Credits</h1>
      <h2>Photo Credits</h2>
      <p>
        <ul>
          <li>
            Photo of a woman getting her blood pressure tested by{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://unsplash.com/@hush52?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            >
              Hush Naidoo Jade Photography
            </a>{' '}
            on{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://unsplash.com/photos/pA0uoltkwao"
            >
              Unsplash
            </a>
            .
          </li>
          <li>
            Photo of man next to dialysis machine smiling provided by{' '}
            <a target="_blank" rel="noreferrer" href="https://www.dciinc.org/">
              Dialysis Clinic, Inc.
            </a>
          </li>
          <li>
            Photo of palm trees on a beautiful Caribbean beach by{' '}
            <a target="_blank" rel="noreferrer" href="https://unsplash.com/@pedropiteiramonteiro?utm_source=unsplash">
              Pedro Monteiro
            </a>
            {' '}on{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://unsplash.com/s/photos/palm-tree-ocean?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            >
              Unsplash
            </a>
            .
          </li>
          <li>
            Any other images on the website are the property of{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://silverli.github.io/"
            >
              Lindsay Silver
            </a>
            .
          </li>
        </ul>
      </p>
    </>
  );
}
